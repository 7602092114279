import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import router from '@/router';
import store from '@/store';
import { http } from '@/services/http';

import App from '@/App.vue';
import '@/registerServiceWorker';

import 'swiper/swiper.scss';
import '@/styles/app.scss';

// Initiate Font Awesome
library.add(fab);
library.add(far);
library.add(fas);

const app = createApp(App);
const head = createHead();

app.config.globalProperties.$http = http;

app.component('fa', FontAwesomeIcon);
app.use(router).use(store).use(head);

router.isReady().then(() => {
  app.mount('#app');
});
