<template>
  <div class="container">
    <div class="flex justify-center items-center w-screen h-screen bg-primary">
      <div class="w-3/5 p-px text-center">
        <div class="h-3 relative rounded-full overflow-hidden">
          <div class="w-full h-full bg-darkgray absolute"></div>
          <div id="bar" class="transition-all ease-out duration-5000 h-full bg-secondary relative w-0"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.startLoading();
  },
  methods: {
    onFinish() {
      setTimeout(() => {
        this.$emit('onFinish', true);
      }, 1200);
    },
    startLoading() {
      const opening = this;
      let progress = 0;
      let invervalSpeed = 150;
      let incrementSpeed = 300;

      let bar = document.getElementById('bar');

      const progressInterval = setInterval(function () {
        progress += incrementSpeed;
        bar.style.width = progress + '%';

        if (progress >= 100) {
          clearInterval(progressInterval);
          opening.onFinish();
        }
      }, invervalSpeed);
    },
  },
};
</script>
