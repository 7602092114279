<template>
  <div class="flex min-h-screen flex-col">
    <Opening @onFinish="onOpeningFinished" v-show="!openingFinished" />
    <section v-show="openingFinished">
      <Navigation />
      <div class="flex-1">
        <router-view />
      </div>
      <Footer />
    </section>
  </div>
</template>

<script>
import Navigation from '@/views/Sections/Navigation';
import Footer from '@/views/Sections/Footer';
import Opening from '@/components/Animation/Opening.vue';

export default {
  components: {
    Navigation,
    Footer,
    Opening,
  },
  data() {
    return {
      openingFinished: false,
    };
  },
  methods: {
    onOpeningFinished(finish) {
      finish ? (this.openingFinished = true) : null;
    },
  },
};
</script>
