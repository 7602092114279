import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/services', component: () => import(/* webpackChunkName: "services" */ '@/views/Services.vue') },
  { path: '/workshop', component: () => import(/* webpackChunkName: "services" */ '@/views/Workshop.vue') },
  { path: '/project', component: () => import(/* webpackChunkName: "project" */ '@/views/Project.vue') },
  {
    path: '/project/:param',
    component: () => import(/* webpackChunkName: "project-detail" */ '@/views/ProjectDetail.vue'),
  },
  // { path: '/smarthome', component: () => import(/* webpackChunkName: "smart-home" */ '@/views/SmartHome.vue') },
  { path: '/contact', component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue') },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      top: 0,
    };
  },
});

export default router;
