const Application = {
  namespaced: true,
  state: {
    menu: false,
  },
  actions: {
    menu({ commit }, { menu }) {
      commit('setState', { menu: menu });
    },
    reset({ commit }) {
      commit('setState', { menu: false });
    },
  },
  mutations: {
    setState(state, param) {
      state.menu = param.menu || false;
    },
  },
};

export default Application;
