<template>
  <nav class="fixed w-full py-4 z-10" :class="headerBg">
    <div class="md:container mx-auto relative px-4 sm:flex sm:justify-between sm:items-center">
      <div class="flex items-center justify-between flex-wrap">
        <router-link class="flex items-center flex-shrink-0 text-white" to="/" @click="hideMenu">
          <img class="w-10 h-auto" src="@/assets/sglogo_icon.png" />
        </router-link>
        <div class="block sm:hidden">
          <button class="menu" @click="onTapMenu">
            <fa class="text-2xl" :icon="['fas', 'bars']" />
          </button>
        </div>
      </div>

      <div class="justify-center h-screen z-20 sm:flex sm:h-auto" :class="showMenu ? 'flex' : 'hidden'">
        <ul class="flex flex-col justify-center text-center text-secondary sm:flex-row">
          <li class="px-4 py-2 text-base hover:text-white">
            <router-link class="nav-item p-2" to="/" @click="hideMenu">Home</router-link>
          </li>
          <li class="px-4 py-2 text-base hover:text-white">
            <router-link class="nav-item" to="/services" @click="hideMenu">Our Values</router-link>
          </li>
          <li class="px-4 py-2 text-base hover:text-white">
            <router-link class="nav-item" to="/workshop" @click="hideMenu">Workshop</router-link>
          </li>
          <li class="px-4 py-2 text-base hover:text-white">
            <router-link class="nav-item" to="/project" @click="hideMenu">Project</router-link>
          </li>
          <!-- <li class="px-4 py-2 text-base hover:text-white">
            <router-link class="nav-item" to="/smarthome" @click="hideMenu">Smart Home</router-link>
          </li> -->
          <li class="px-4 py-2 text-base hover:text-white">
            <router-link class="nav-item" to="/contact" @click="hideMenu">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
      scrollPosition: null,
      headerBg: 'bg-transparent',
    };
  },
  methods: {
    onTapMenu() {
      this.showMenu = !this.showMenu;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      this.setHeaderBackground();
    },
    setHeaderBackground() {
      this.showMenu || this.scrollPosition !== 0 ? (this.headerBg = 'bg-primary') : (this.headerBg = 'bg-transparent');
    },
    hideMenu() {
      this.showMenu = false;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    window.matchMedia('(min-width: 768px)').addEventListener('change', this.hideMenu);
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll);
    window.matchMedia('(min-width: 768px)').removeEventListener('change', this.hideMenu);
  },
  watch: {
    showMenu: function () {
      this.setHeaderBackground();
    },
  },
};
</script>

<style lang="scss">
.menu {
  @apply flex items-center px-3 py-2 text-secondary text-xl;
}

.nav-item.router-link-exact-active {
  @apply text-white;
}
</style>
