/**
 * HTTP application service.
 *
 * @author Effene Herry <effene.hrr@gmail.com>
 */

import axios from 'axios';
import store from '@/store';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

// Axios global response / error handling.
http.interceptors.response.use(
  (res) => {
    return {
      data: res.data,
      status: res.status,
    };
  },
  (error) => {
    let response = {};

    // Handling error with HTTP response.
    if (error.response) {
      if (error.response.status === 500) {
        // We will show the server error popup when server show the HTTP 500 error.
        store.dispatch('Error/global');
      } else if (error.response.status === 404) {
        store.dispatch('Error/global');
      } else {
        response.status = error.response.status;
        response.message = error.response.data.error.message;
      }
    } else {
      // Handle client error like net:ERR_CONNECTION_REFUSED, etc.
      store.dispatch('Error/global');
    }

    return Promise.reject(response);
  },
);

export { http };
