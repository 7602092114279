<template>
  <section>
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :space-between="0"
      :autoplay="{ delay: 4000, disableOnInteraction: false }"
      :loop="true"
      navigation
      :pagination="{ clickable: true }"
      :scrollbar="{ draggable: true }"
    >
      <swiper-slide v-for="(project, key) in carousels" :key="`carousel-${key}`">
        <figure class="carousel-item">
          <img class="w-full h-full object-cover" :src="project.carousel" />
          <figcaption class="carousel-caption">
            <div class="w-full h-full flex justify-center items-center text-2xl lg:text-4xl">
              <router-link :to="`/project/${project.slug}`">{{ project.title }}</router-link>
            </div>
          </figcaption>
        </figure>
      </swiper-slide>
    </swiper>
  </section>

  <section>
    <h1 class="text-4xl text-center text-primary mt-16">About Us</h1>
    <div class="flex flex-col lg:flex-row md:items-center py-4 md:py-8">
      <div class="p-4 mx-auto">
        <img class="max-h-80 rounded-full" src="@/assets/logo_about_us.png" />
      </div>
      <div class="lg:w-3/5 px-4 md:px-8 lg:px-12">
        <!-- <h2 class="text-3xl text-primary mb-8">About Us</h2> -->
        <div class="quote my-8">
          <h2 class="text-primary text-center">COMMITED to Superior Quality and Results.</h2>
        </div>
        <p class="text-lg mb-8">
          Sg Interior or PT. Sinar Gemilang Internasional is a full-service architecture, interior, and furniture
          contractor providing design and build services for our customers.
        </p>
        <p class="text-lg">
          Collaborating with well-known consultants and reliable partners in every project, SG Interior prioritizes
          professionality and trust to get superior quality results and services that exceeds your expectations.
        </p>
      </div>
    </div>
  </section>

  <section>
    <div class="md:container mx-auto py-16 text-center">
      <h1 class="text-4xl text-primary mb-6">Featured Project</h1>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-12 px-4">
        <Thumbnail
          v-for="project in featured"
          :key="project.id"
          :title="project.title"
          :thumbnail="project.featured"
          :route="`/project/${project.slug}`"
        />
      </div>
      <router-link class="inline-block bg-primary p-4 rounded-lg text-lg text-secondary hover:text-white" to="/project">
        See All Project
      </router-link>
    </div>
  </section>

  <section>
    <h1 class="text-4xl text-center text-primary mt-8">Our Client</h1>
    <div class="flex flex-wrap justify-center gap-8 p-8 mb-8">
      <a href="https://growtech.id/" target="_blank">
        <img class="logo object-contain" src="@/assets/client/growtech.png" />
      </a>
      <img class="logo object-contain" src="@/assets/client/ufish.png" />
      <img class="logo object-contain" src="@/assets/client/arthanindo.png" />
      <img class="logo object-contain" src="@/assets/client/bank_kalsel.png" />
      <img class="logo object-contain" src="@/assets/client/diamond_co.png" />
      <img class="logo object-contain" src="@/assets/client/jks.png" />
      <img class="logo object-contain" src="@/assets/client/satrindo_mitra_utama.png" />
      <img class="logo object-contain" src="@/assets/client/naughty_olive.png" />
      <img class="logo object-contain" src="@/assets/client/mattaka.png" />
      <img class="logo object-contain" src="@/assets/client/passion_jewelry.png" />
      <img class="logo object-contain" src="@/assets/client/passion_prive.png" />
      <img class="logo object-contain" src="@/assets/client/oryx.png" />
    </div>
  </section>
</template>

<script>
import { useHead } from '@vueuse/head';
import { Autoplay, A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import Thumbnail from '@/components/Thumbnail.vue';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

export default {
  components: {
    Swiper,
    SwiperSlide,
    Thumbnail,
  },
  setup() {
    useHead({
      title: `Home | ${process.env.VUE_APP_NAME}`,
    });
    return {
      modules: [Autoplay, A11y, Navigation, Pagination, Scrollbar],
    };
  },
  data() {
    return {
      projects: [],
    };
  },
  mounted() {
    this.$http.get(`/project.php`).then((response) => {
      this.projects = response.data;
    });
  },
  computed: {
    carousels() {
      let carousels = [];
      this.projects.forEach((project) => {
        if (project.carousel != '') {
          carousels.push(project);
        }
      });

      return carousels;
    },
    featured() {
      let featured = [];
      this.projects.forEach((project) => {
        if (project.featured != '') {
          featured.push(project);
        }
      });

      return featured;
    },
  },
};
</script>
