<template>
  <router-link class="relative group" :to="route">
    <img class="w-full h-full border border-white" :src="thumbnail" />
    <div class="thumbnail-container group-hover:opacity-100">
      <div
        class="
          thumbnail
          transition
          transform
          duration-700
          ease-in-out
          group-hover:scale-100 group-hover:opacity-100
          px-2
        "
      >
        <div class="text-center">
          <p class="md:text-xl">
            {{ title }}
          </p>
          <p class="text-sm">{{ categories }}</p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    categories: String,
    thumbnail: String,
    title: String,
    route: String,
  },
};
</script>

<style lang="scss">
.thumbnail-container {
  @apply absolute bottom-0 md:inset-0 w-full p-6 md:p-12;
  @apply bg-black bg-opacity-40 md:opacity-0 overflow-hidden;
  @apply transition transform duration-700 ease-in-out;
}
.thumbnail {
  @apply flex justify-center items-center h-full p-4 md:p-0;
  @apply border border-white md:opacity-0 md:scale-150 text-white;
}
</style>
