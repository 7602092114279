<template>
  <div class="bg-primary text-secondary">
    <div class="md:container mx-auto px-4 py-8">
      <section class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div class="mb-6">
          <h4 class="text-xl mb-4">Contact Us</h4>
          <address class="not-italic">
            Sentra Industri Terpadu 1 &amp; 2, Blok i1 no. 7<br />
            Elang Laut PIK, Jakarta, Indonesia<br />
            Jakarta Utara 14470
          </address>
        </div>
        <div class="mb-6">
          <h4 class="text-xl mb-4">Reach Us</h4>
          <ul class="flex">
            <li>
              <a class="text-xl mr-4 md:mr-8" href="https://www.instagram.com/sginterior.id/" target="_blank">
                <fa :icon="['fab', 'instagram']" />
              </a>
            </li>
            <li>
              <a class="text-xl mr-4 md:mr-8" href="https://wa.me/6289634539713" target="_blank">
                <fa :icon="['fab', 'whatsapp']" />
              </a>
            </li>
            <li>
              <a class="text-xl" href="mailto:sginterior.id@gmail.com">
                <fa :icon="['far', 'envelope']" />
              </a>
            </li>
          </ul>
        </div>
      </section>
      <footer>
        <small>Copyright &copy; 2021 SG Interior. All rights reserved.</small>
      </footer>
    </div>
  </div>
</template>
