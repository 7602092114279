const Error = {
  namespaced: true,
  state: {
    error: false,
  },
  actions: {
    global({ commit }) {
      commit('setState', {
        error: true,
      });
    },
    reset({ commit }) {
      commit('setState', { error: false });
    },
  },
  mutations: {
    setState(state, param) {
      state.error = param.error || false;
    },
  },
};

export default Error;
